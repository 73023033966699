<template>
  <div class="course_warp">
    <div class="top">
      <div class="card">
        <!-- <img src="@/assets/course/善利名师讲义@2x.png"
             @click="toPage('/slHandouts')"
             alt="">
        <img src="@/assets/course/公益讲义@2x.png"
             @click="toPage('/publicHandouts')"
             alt=""> -->
        <div class="imgItem"
             @click="toPage('/slHandouts')"
             :style="{backgroundImage:'url('+require('@/assets/course/bg-善利名师讲义@2x.png')+')'}">

          <div class="title">
            <span class="icon"><img src="@/assets/course/icon-复习讲义@2x.png"
                   alt=""></span><span>复习讲义</span>
          </div>
          <div class="sub_title">巩固基础，把握重难点</div>
          <div class="btn">讲义详情 <span class="icon"><img src="@/assets/course/icon-更多@2x.png"
                   alt=""></span></div>

        </div>
        <div class="imgItem"
             @click="toPage('/lecture_free')"
             :style="{backgroundImage:'url('+require('@/assets/course/bg-公益讲义@2x.png')+')'}">
          <div class="title">
            <span class="icon"><img src="@/assets/course/icon-公益讲义@2x.png"
                   alt=""></span><span>公益讲义</span>
          </div>
          <div class="sub_title">课程同步视频讲解</div>
          <div class="btn">讲义详情 <span class="icon"><img src="@/assets/course/icon-更多@2x.png"
                   alt=""></span></div>
        </div> 
        <div class="imgItem"
             @click="toPage('/shcoolVideo_more')"
             :style="{backgroundImage:'url('+require('@/assets/course/本校资源视频.png')+')'}">

          <div class="title">
            <span class="icon"><img src="@/assets/course/tv-01.png"
                   alt=""></span><span>本校资源</span>
          </div>
          <div class="sub_title">本校视频讲解</div>
          <div class="btn">资源列表 <span class="icon"><img src="@/assets/course/icon-更多@2x.png"
                   alt=""></span></div>

        </div>
      
        <!-- <img src="@/assets/course/专题讲解@2x.png"
             @click="toPage('/lecture')"
             alt="">
        <img src="@/assets/course/知识点讲解.png"
             @click="toPage('/knowledgeExplain')"
             alt=""> -->
      </div>
    </div>
    <!-- <div class="knowledge">
      <div class="title">
        <span>
          考点讲解
          {{userInfo.edu_grade_id == 1?'考点讲解':'知识点讲解'}}
        </span>
        <span @click="toPage('/knowledgeVideo')"
              style="cursor:pointer">
          更多<img src="@/assets/index/more.png"
               alt="">
        </span>
      </div>
      <div class="progress">
        <div class="card"
             v-for="item in list"
             :key="item.pic">
          <div class="subject"
               :style="{'background':$subject_styles[item.subject_id].subject_backg_color,
               'color':$subject_styles[item.subject_id].text_color}">
            {{item.subject_name}}
          </div>
          <div class="name">
            {{item.teaching_video_name}}
          </div>
          <div class="start">
            <img src="@/assets/icons/icon-星星.png"
                 v-for="start in item.knowledge_point_star"
                 :key="start"
                 alt="">
          </div>
          <div class="btn"
               @click="showVideo(item.video_link)"
               :style="{'background':$subject_styles[item.subject_id].text_color}">
            开始播放 <img src="@/assets/icons/bf.png"
                 alt="">
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="knowledge">
      <div class="title">
        <span>
          公益讲座 -->
          <!-- {{userInfo.edu_grade_id == 1?'考点讲解':'知识点讲解'}} -->
        <!-- </span>
        <span @click="toPage('/lecture_free')"
              style="cursor:pointer">
          更多<img src="@/assets/index/more.png"
               alt="">
        </span>
      </div>
      <div class="progress">
        <div class="card_lecture"
             v-for="item,index in lectureList"
             @click="showVideo(item.url)"
             :key="item.url+''+index">
          <div class="preview_img">
            <img v-if="item.pic"
                 :src="item.pic"
                 alt=""
                 :style="{'backgroundImage':'url('+require('@/assets/course/视频默认.png')+')'}">
            <video :src="item.url"
                   v-else
                   disablePictureInPicture
                   width="100%"
                   height="100%"
                   :style="{'backgroundImage':'url('+require('@/assets/course/视频默认.png')+')'}" />
          </div>
          <div class="name">
            {{item.title}}
          </div>

        </div>
      </div>
    </div> -->
    <!-- <div class="knowledge">
      <div class="title">
        <span>
          本校视频
        </span>
        <span @click="toPage('/shcoolVideo_more')"
              style="cursor:pointer">
          更多<img src="@/assets/index/more.png"
               alt="">
        </span>
      </div>
      <div class="progress">
        <div class="card_school"
             v-for="item,index in shcoolVideoList"
             @click="showVideo(item.url)"
             :key="item.url+''+index">
          <div class="preview_img">
            <img v-if="item.pic"
                 :src="item.pic"
                 alt=""
                 :style="{'backgroundImage':'url('+require('@/assets/course/视频默认.png')+')'}">
            <video :src="item.url"
                   v-else
                   disablePictureInPicture
                   width="100%"
                   height="100%"
                   :style="{'backgroundImage':'url('+require('@/assets/course/视频默认.png')+')'}" />
          </div>
          <div class="name">
            {{item.filename}}
          </div>

        </div>
      </div>
    </div> -->
    <div class="autoModal"
         v-if="autoModalFlag">
      <div class="openButton">

        <el-button type="warning"
                   @click="toBuy"
                   class="btntext"><span class="text">开通VIP享受全部功能</span></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeLecture, getHomeList,getSchoolList } from '@/api/lecture.js'
export default {
  data () {
    return {
      list: [],
      lectureList: [],
      shcoolVideoList: [],
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      autoModalFlag: false
    }
  },

  computed: {

  },
  mounted () {
    this.initData()
    this.getLecture()
    this.getSchool()
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
      this.autoModalFlag = true
    }
  },
  methods: {
    toBuy () {
      this.$router.push('/buyVip')
    },
    async initData () {
      const { data } = await getHomeLecture()
      // console.log(data)
      this.list = data.list
    },
    async getLecture () {
      const { data } = await getHomeList()
      this.lectureList = data.list
    },
    async getSchool() {
      const { data } = await getSchoolList({edu_school_id:this.userInfo.edu_school_id,type:"video"})
      this.shcoolVideoList = data.list
    },
    toPage (path) {
      this.$router.push(path)
    },
    showVideo (url) {
      // window.open(url, '_blank')
      this.$router.push("/video?video=" + url)
    }
  }
}
</script>

<style lang="scss" scoped>
.course_warp {
   .autoModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1.5;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    .btntext {
      position: fixed;
      left: 800rem;
      top: 700rem;
      .text {
        color: var(--light-333333, #333);
        text-align: center;

        font-size: 24rem;
        font-style: normal;
        font-weight: 600;
        line-height: 36rem; /* 150% */
      }
    }
    .openButton {
      ::v-deep .el-button {
        display: flex;
        width: 360rem;
        height: 64rem;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        flex-shrink: 0;
        border-radius: 8rem;
        background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
        box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
      }
    }
  }
  .top {
    display: flex;
    // overflow: auto;
    // width: 96%;
    // margin: 0 20rem 10rem;
    .card {
      flex: 1;
      margin-top: 3.5vh;
      margin-right: 60rem;
      display: flex;
      .imgItem {
        width: 560rem;
        height: 290rem;
        margin-left: 2.13vw;
        padding: 30rem 40rem;
        color: #fff;
        background-size: 100% 100%;
        box-sizing: border-box;
        .icon {
          width: 35rem;
          height: 36rem;
          margin-right: 16rem;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
            vertical-align: middle;
          }
        }

        .title {
          font-size: 22rem;
          // padding-left: 16rem;
        }
        .sub_title {
          font-size: 18rem;
          margin-top: 20rem;
          line-height: 30rem;
        }
        .btn {
          font-size: 18rem;
          margin-top: 80rem;
          .icon {
            width: 22rem;
            height: 22rem;
            padding-left: 8rem;
          }
        }
      }
      // img {
      //   // flex: 1;
      //   width: 27vw;
      //   margin-left: 3.13vw;
      // }
    }
  }
  .knowledge {
    margin-top: 30rem;
    width: calc(100% - 120rem);
    margin-left: 60rem;
    .title {
      font-size: 24rem;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: space-between;
      height: 24rem;
      line-height: 24rem;
      img {
        width: 24rem;
        height: 24rem;
        margin-left: 14rem;
      }
    }
    .progress {
      display: flex;
      overflow-x: auto;
      padding-bottom: 10rem;
      .card {
        width: 280rem;
        height: 240rem;
        border-radius: 15rem;
        box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
        background: #ffffff;
        flex-shrink: 0;
        margin-right: 30rem;
        margin-top: 30rem;
        cursor: pointer;
        .subject {
          width: 120rem;
          height: 44rem;
          border-radius: 1rem 1rem 15rem 15rem;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          font-size: 20rem;
          font-weight: bold;
          line-height: 28rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .name {
          font-weight: bold;
          color: #333333;
          font-size: 20rem;
          margin-top: 20rem;
          width: 240rem;
          height: 56rem;
          margin-left: 20rem;
        }
        .start {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20rem;
          margin-right: 10rem;
        }
        .btn {
          width: 180rem;
          height: 44rem;
          background: #557ef2;
          border-radius: 40rem;
          font-size: 20rem;
          margin-top: 20rem;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          img {
            width: 22rem;
            height: 22rem;
            margin-left: 12rem;
          }
        }
      }
      .card_lecture {
        width: 260rem;
        height: 180rem;
        border-radius: 15rem;
        padding: 10rem;
        box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
        background: #ffffff;
        flex-shrink: 0;
        margin-right: 30rem;
        margin-top: 30rem;
        cursor: pointer;
        .preview_img {
          margin: 0 auto;
          width: 100%;
          height: 120rem;
          img,
          video {
            width: 100%;
            height: 100%;
            background-size: 80% 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .name {
          font-weight: bold;
          color: #333333;
          font-size: 20rem;
          margin-top: 10rem;
          width: 260rem;
          height: 56rem;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }
      .card_school {
        width: 260rem;
        // height: 180rem;
        border-radius: 15rem;
        padding: 10rem;
        box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
        background: #ffffff;
        flex-shrink: 0;
        margin-right: 30rem;
        margin-top: 30rem;
        cursor: pointer;
        .preview_img {
          margin: 0 auto;
          width: 100%;
          height: 120rem;
          img,
          video {
            width: 100%;
            height: 100%;
            background-size: 80% 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .name {
          font-weight: bold;
          color: #333333;
          font-size: 20rem;
          margin-top: 10rem;
          width: 260rem;
          height: 56rem;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>